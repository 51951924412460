* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.App {
  text-align: center;
}
h1{
  font-size: 3rem;
  font-weight: bolder;
  display: block;
}
p {
  font-size: 1.5rem;
  margin: 1rem;
}
section {
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

a {
  color: white;
  display: inline-block;
  font-weight: bold;
  text-decoration: underline;
  margin: 1rem;
  font-size: 1.5rem;
}

a i {
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  display: inline-block;
  padding: 1rem 2rem;
}
a i:hover {
  border: 2px solid black;
  color: black;
}

li {
  font-size: 1.5rem;
  text-align: left;
}


.intro{
  background-color: rgb(234, 71, 65);
}
.contents1 {
  background-color: rgb(64,139,159);
}
.contents2 {
  background-color: rgb(219, 126, 157);
}
#stick {
  background-color: rgb(237, 156, 67);
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer {
  background-color: whitesmoke;
  color: seagreen;
}

.intro-p{
  max-width: 900px;
}


.thumbnail__div {
  flex: 0 0 33.3333333%;
  position: relative;
}

.responsive-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.thumbnail-info {
  background-color: rgba(0,0,0, 0.3);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 300px;

  display: flex; 
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  transition: all 0.4s;
}

.thumbnail__div:hover .thumbnail-info {
  height: 100%;
  background-color: rgba(0,0,0, 0.6);
}






html.has-scroll-smooth {
  overflow: hidden; 
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

.has-scroll-smooth body {
  overflow: hidden; 
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh; 
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap; 
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%; 
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0; 
}

  .c-scrollbar:hover {
    transform: scaleX(1.45); 
}

  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; 
}

[data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); 
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
      transform: scaleY(1.3); 
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab; 
}
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing; 
}
  [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0; 
}





