.container-rte {
    background-color: rgb(219, 126, 157);
    height: 100% !important;
    width: 100%;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    align-content: center;
    a {
        color: white;
        display: inline-block;
        font-weight: bold;
        text-decoration: underline;
        margin: 1rem;
        font-size: 1.2rem;
        i {
            border: 2px solid white;
            border-radius: 10px;
            color: white;
            display: inline-block;
            padding: 1rem 2rem;
        }
        i:hover {
            border: 2px solid black;
            color: black;
        }
    }
}
